var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "battery" }, [
      _c("span", { staticClass: "batteryIcon" }, [
        _c("span", { staticClass: "batteryIconBody" }, [
          _c("span", {
            class: [
              "batteryIconProgress",
              _vm.text > 0 && _vm.text <= 25
                ? "bgpink"
                : _vm.text > 25 && _vm.text <= 50
                ? "bgorange"
                : _vm.text > 50 && _vm.text <= 75
                ? "bgblue"
                : _vm.text > 75 && _vm.text <= 100
                ? "bggreen"
                : "",
            ],
            style: { width: _vm.text !== null ? _vm.text + "%" : 0 },
            attrs: { id: "batteryIconProgress" },
          }),
        ]),
        _c(
          "label",
          {
            staticClass: "batteryIconContent",
            attrs: { id: "batteryIconContent" },
          },
          [_vm._v(_vm._s(_vm.text !== null ? _vm.text + "%" : ""))]
        ),
        _c("span", { staticClass: "batteryIconHead" }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }