<template>
  <div>
    <div class="battery">
      <span class="batteryIcon">
        <span class="batteryIconBody">
          <span id="batteryIconProgress" :class="['batteryIconProgress',text>0&&text<=25?'bgpink':(text>25&&text<=50?'bgorange':(text>50&&text<=75?'bgblue':(text>75&&text<=100?'bggreen':'')))]" :style="{width:text!==null?text+'%':0}"></span>
        </span>
        <label class="batteryIconContent" id="batteryIconContent">{{ text!==null?text+'%':'' }}</label>
        <span class="batteryIconHead"></span>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    text: {
        type: Number,
        default: 0
    }
  },
  data () {
    return {}
  }
}
</script>
<style lang="less" scoped>

.battery {
    width: 90px;
    margin: 0 auto;
}

span {
    display: block;
}

.batteryIcon {
    position: relative;
    width: 80px;
    height: 30px;
    border: 2px solid #ccc;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 2px;
    text-align: initial;
}

.batteryIconHead {
    position: relative;
    height: 15px;
    width: 6px;
    display: block;
    position: absolute;
    top: 6px;
    left: 76px;
    border-top:2px solid #ccc;
    border-right:2px solid #ccc;
    border-bottom:2px solid #ccc;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.batteryIconBody {
  width: calc(100% - 4px);
  margin: 2px;
  height: 22px;
  display: block;
  border-radius: 1px;
  -webkit-border-radius: 1px;
}

.batteryIconProgress {
  height: 22px;
  margin: 1px 0;
  border-radius: 1px;
  -webkit-border-radius: 1px;
}

.batteryIconContent {
    color: #000;
    position: relative;
    font-size: 12px;
    left: 27px;
    top: -24px;
}

.beginButton {
    margin-top: 100px;
}

.bgpink{
    background: #ff9595;
}
.bgorange{
    background: #ffdb85;
}
.bgblue{
    background: rgb(144, 197, 240);
}
.bggreen{
    background: #a3f384;
}
</style>
